import React, { Component } from "react";
//import { Helmet, HelmetProvider } from "react-helmet-async";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import $ from "jquery";
import { ValidateEmail, axiosConfig, normalize } from "../../config/utility";
const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      dnsList: [],
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      themeData: this.props.theme_data,
    };
  }

  get_props = () => {
    return this.props.theme_data;
  };
  scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
  
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  pre_loader() {
    $(function() {
      setTimeout(function() {
        $('#consua-preloader').addClass('loaded');
        $("#loading").fadeOut(500);
  
        if ($('#consua-preloader').hasClass('loaded')) {
          $('#preloader').delay(900).queue(function() {
            $(this).remove();
          });
        } else {
          $('#consua-preloader').addClass('loaded');
          $("#loading").fadeOut(500);
        }
      }, 2000);
    });
  }

  componentDidMount() {
    
    let theme_data = this.get_props();
    var propVal = theme_data;
    this.pre_loader();
    $(document).on("click", "#send_message", function (e) {
      e.preventDefault();
      console.log("clicked", propVal);

      //const { name, email, phone, subject, message } = this.state;
      let name = $("#name").val();
      let email = $("#email").val();
      let phone = $("#phone").val();
      let subject = $("#subject").val();
      let message = $("#message").val();
      console.log({
        name: name,
        email: email,
        subject: subject,
        token: process.env.REACT_APP_API_TOKEN,
        api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
        phone: phone,
        message: message,
        reseller_name: propVal.reseller_detail[0].reseller_comp,
        agent_email: propVal.sharelink_settings[0].contact_email,
        agent_name: propVal.agent_data[0]["name"],
        sharelink_id: propVal.sharelink_settings[0].sharelink_id,
      });
      let is_validated = true;
      if (name === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Name is required</div>'
        );
        is_validated = false;
      } else if (subject === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Subject is required</div>'
        );
        is_validated = false;
      } else if (email === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Email is required</div>'
        );
        is_validated = false;
      } else if (phone === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Phone is required</div>'
        );
        is_validated = false;
      } else if (message === "") {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Message is required</div>'
        );
        is_validated = false;
      } else if (ValidateEmail(email) === false) {
        $("#aler_error").html(
          '<div class="alert alert-danger" role="alert">Invalid email format.</div>'
        );

        is_validated = false;
      } else {
        is_validated = true;
      }
      if (is_validated === true) {
        try {
          axios
            .post(
              process.env.REACT_APP_API_URL + "agents/submit_inquiry",
              JSON.stringify({
                name: name,
                email: email,
                subject: subject,
                token: process.env.REACT_APP_API_TOKEN,
                api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
                phone: phone,
                message: message,
                reseller_name: propVal.reseller_detail[0].reseller_comp,
                agent_email: propVal.sharelink_settings[0].contact_email,
                agent_name: propVal.agent_data[0]["name"],
                sharelink_id: propVal.sharelink_settings[0].sharelink_id,
              }),
              axiosConfig
            )
            .then((response) => {
              console.log("Contact Response.data : ", response.data);
              if (response.data.status === 404) {
                $("#aler_success").html("");
                $("#aler_error").html(
                  '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
                );
              }

              if (
                response.data.status === 200 &&
                response.data.message === "success"
              ) {
                $("#aler_error").html("");
                $("#aler_success").html(
                  '<div class="alert alert-success" role="alert">Your inquiry has been sent successfully. We will contact you shortly.</div>'
                );
                $("#name").val("");
                $("#phone").val("");
                $("#subject").val("");
                $("#message").val("");
                $("#email").val("");
              }
            });
        } catch (error) {
          $("#aler_success").html("");
          $("#aler_error").html(
            '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
          );
          console.log("catch : ", error);
        }
      } else {
      }
      setTimeout(() => {
        $("#aler_error").html("");
        $("#aler_success").html("");
      }, 5000);
    });

    $(document).on("click", ".download_rate_deck", function () {
      console.log("clicked", propVal);
      var element = $(this);
      var parent = element.closest(".download_rate_deck");
      var page_id = parent.find(".downloadratedeck").val();
      console.log("PAGE ID: ", page_id);
      if (page_id !== "") {
        try {
          axios
            .post(
              process.env.REACT_APP_API_URL + "agents/get_rate_deck_fileurl",
              JSON.stringify({
                token: process.env.REACT_APP_API_TOKEN,
                api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
                page_id: page_id,
                reseller_accountno: propVal.agent_data[0]["reseller_accountno"],
              }),
              axiosConfig
            )
            .then((reportsData) => {
              console.log("Contact Response.data : ", reportsData.data);
              if (reportsData.data.status === 404) {
                $("#aler_success").html("");
                $("#aler_error").html(
                  '<div class="alert alert-danger" role="alert">There is some error while submitting your inquiry.</div>'
                );
              }

              if (
                reportsData.data.status === 200 &&
                reportsData.data.message === "success"
              ) {
                var fileName = "";
                var url = reportsData.data.data;
                var a = document.createElement("a");
                a.href = url;
                fileName = url.split("/").pop();
                //alert(fileName);
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              }
            });
        } catch (error) {
          console.log("catch : ", error);
        }
      } else {
        console.log("Page ID is missing");
      }
      //window.location.href = click_route;
    });
  }

  handlePhoneNumber = (value) => {
    return value.replace(phoneRegex, "($1) $2-$3");
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  getSrviceTitle = (service_id) => {
    let theme_data = this.get_props();
    var propVal = theme_data.all_services;
    console.log("getSrviceTitle propVal: ", propVal);

    for (var i = 0, len = propVal.length; i < len; i++) {
      if (propVal[i].id === service_id) {
        let service_type = "";
        if (propVal[i].service_type === "did") {
          service_type = "DID Origination";
        } else if (propVal[i].service_type === "tfn") {
          service_type = "Toll Free Origination";
        } else if (propVal[i].service_type === "term") {
          service_type = "Voice Termination";
        } else if (propVal[i].service_type === "teams") {
          service_type = "Microsoft Teams UCAAS";
        } else if (propVal[i].service_type === "tfn_term") {
          service_type = "Toll Free Termination";
        } else if (propVal[i].service_type === "az_term") {
          service_type = "International A-Z Termination";
        } else if (propVal[i].service_type === "live_agent") {
          service_type = "Live Agent";
        } else if (propVal[i].service_type === "cnam") {
          service_type = "CNAM";
        } else if (propVal[i].service_type === "sms") {
          service_type = "SMS";
        }

        return (
          <>
            <td>{propVal[i].service_title}</td>
            <td>{service_type}</td>
          </>
        );
      }
    }
    return null;
  };

  fieldValidation = () => {
    let { name, email, phone, subject, message } = this.state;

    if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
        errorcPass: "",
      });
      return false;
    } else if (subject === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Subject is required.",
        errorcPass: "",
      });
      return false;
    } else if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorTitle: "",
        errorMessage: "Email is required.",
        errorcPass: "",
      });
      return false;
    } else if (message === "") {
      this.setState({
        errorMessage: "error",
        errorEmail: "",
        errorMessage: "Message is required.",
        errorTitle: "",
        errorcPass: "",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
        errorcPass: "",
      });
      return false;
    } else {
      this.setState({
        errorcPass: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        disabled: true,
      });
    }
    return true;
  };

  getColSize = () => {
    const length = this.props.theme_data.sharelink_services.length;

    // When there is only one item
    if (length === 1) {
      return "12";
    }

    // When the length is even, set col-lg-6 for the first div, else set col-lg-4
    return length % 2 === 0 ? "6" : "4";
  };
  
  render() {
    const { theme_data, key } = this.props;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content={this.props.theme_data.sharelink[0]["meta_description"]}
            />
            <title>{this.props.theme_data.sharelink[0]["meta_title"]}</title>
            
          </Helmet>
        </HelmetProvider>

        {/* === START PRELOADER === */}
        <div id="preloader">
          <div id="consua-preloader" className="consua-preloader">
            <div className="animation-preloader">
              <div className="spinner"></div>
              <div className="txt-loading">
                {this.props.theme_data.agent_data[0].comp_name.split("").map((letter, index) => (
                  <span key={index} data-text-preloader={letter} className="letters-loading">
                    {letter}
                  </span>
                ))}
              </div>
            </div>
            <div className="loader">
              <div className="row">
                <div className="col-3 loader-section section-left">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-left">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                  <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                  <div className="bg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* === END PRELOADER === */}
        {/* === START HEADER === */}
        <header>
          {/* Start Navigation */}
          <nav className="navbar mobile-sidenav navbar-sticky navbar-default validnavs navbar-fixed dark no-background no-background-theme-2">
            <div className="container d-flex justify-content-between align-items-center">

              {/* Start Header Navigation */}
              <div className="navbar-header">
                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                  <i className="fa fa-bars"></i>
                </button>
                <a className="navbar-brand" href="#">
                  {this.props.theme_data.logos ? (
                    <img
                      src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                      alt={this.props.theme_data.logos[0]["image_title"]}
                      className="logo" 
                    />
                  ) : (
                    <img src="../../../assets/theme/img/logo/dark.png" alt="" />
                  )}
                </a>
              </div>
              {/* End Header Navigation */}

              {/* Collect the nav links, forms, and other content for toggling */}
              <div className="collapse navbar-collapse" id="navbar-menu">
                <div className="collapse-header">
                  {this.props.theme_data.logos ? (
                    <img
                      src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                      alt={this.props.theme_data.logos[0]["image_title"]}
                      className="logo" 
                    />
                  ) : (
                    <img src="../../../assets/theme/img/logo/dark.png" alt="" />
                  )}
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                    <i className="fa fa-times"></i>
                  </button>
                </div>

                <ul className="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
                    <li><a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("home");}}>Home</a></li>
                    <li><a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("about")}}>About Us</a></li>
                    <li><a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("service")}}>Services</a></li>
                    <li><a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("contact")}}>contact</a></li>
                </ul>
              </div>

              <div className="attr-right">
                {/* Start Attribute Navigation */}
                <div className="attr-nav">
                  <ul>
                    <li className="contact">
                      <div className="call">
                        <div className="icon">
                          <i className="fas fa-comments-alt-dollar"></i>
                        </div>
                        <div className="info">
                          <p>Have any Questions?</p>
                          <h5><a href="#">{this.props.theme_data.agent_data[0]["email"]}</a></h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* End Attribute Navigation */}
              </div>

            </div>
            {/* Overlay screen for menu */}
            <div className="overlay-screen"></div>
            {/* End Overlay screen for menu */}
          </nav>
          {/* End Navigation */}
        </header>
        {/* === END HEADER === */}
        {/* === START BANNER AREA === */}
        <div id="home" className="banner-area banner-style-two content-right navigation-icon-solid navigation-right-botom navigation-custom-large overflow-hidden bg-cover" style={
            this.props.theme_data.banners
              ? {
                  background: `url(${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.banners[0]["picture"]})`,
                }
              : {
                  background: 'url(../../../assets/theme/img//banner.jpg)',
                }
          }>
          {/* Slider main container */}
          <div className="banner-style-two-carousel">
            {/* Additional required wrapper */}
            <div className="swiper-wrapper">

              {/* Single Item */}
              <div className="swiper-slide banner-style-two">
                <div className="container">
                  <div className="row align-center">
                    <div className="col-xl-7 col-lg-9">
                      <div className="content">
                        <h2>{this.props.theme_data.agent_data[0].name}{" "}</h2>
                        <p>
                          {this.props.theme_data.sharelink_settings[0].about_me_short}
                        </p>
                        <div className="button">
                          <a className="btn btn-theme btn-md animation" href={this.props.currentUrl}  target="_blank">REQUEST FOR QUOTATION</a>
                        </div>
                        <div className="shape-circle"></div>
                      </div>
                    </div>
                  </div>
                  {/* Banner Thumb */}
                  {this.props.theme_data.banners
                    ? null
                    : (
                      <div className="banner-thumb">
                        <img src="../../../assets/theme/img/illustration/1.png" alt="illustration" />
                      </div>
                    )
                  }
                  {/* End Banner Thumb */}
                </div>
                {/* Start Shape */}
                <div className="banner-shape-right" style={{ background: "url(../../../assets/theme/img/shape/3.png)" }}></div>
                {/* End Shape */}
              </div>
              {/* End Single Item */}
            </div>
          </div>
        </div>
        {/* === END BANNER AREA === */}
        {/* === START ABOUT === */}
        <div id="about" className="feature-style-one-area half-angle-shape overflow-hidden default-padding">
          <div className="container">
            <div className="row align-center">
              {/* Single Item */}
              <div className="col-lg-5">
                <div className="feature-style-one-heading text-light">
                  <div className="arrow-shape">
                    <img src="../../../assets/theme/img/shape/21.png" alt="Image not found" />
                  </div>
                  <h2 className="title mb-25">About Us</h2>
                  {this.props.theme_data.avatars ? (
                    <img className="wow fadeInRight" src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.avatars[0]["picture"]}`} alt="Image Not Found" />
                  ) : (
                    null
                  )}
                </div>
              </div>
              {/* End Single Item */}
              <div className="col-lg-6 offset-lg-1 pl-60 pl-md-15 pl-xs-10 mt-md-50 mt-xs-50">

                {/* Single Item */}
                <div className="feature-style-one wow fadeInUp">
                  <div className="info">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.theme_data.sharelink_settings[0].about_me,
                      }}
                    />
                  </div>
                </div>
                {/* End Single Item */}

              </div>
            </div>
          </div>
        </div>
        {/* === END ABOUT === */}
        {/* === START SERVICES === */}
        <div id="service" className="services-style-two-area default-padding bottom-less bg-cover bg-gray"  style={{ backgroundImage: "url(../../../assets/theme/img/shape/27.png)" }}>
          <div class="container">
              <div class="row">
                  <div class="col-lg-8 offset-lg-2">
                      <div class="site-heading text-center">
                          <h4 class="sub-heading">What we do</h4>
                          <h2 class="title">Product & Services We're Offering</h2>
                          <div class="devider"></div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
            <div className="row customRow-theme4">
              {this.props.theme_data.sharelink_services.map(
                (services) => (
                  <div key={`serv${services.id}`} className={`col-lg-${this.getColSize()} col-xl-${this.getColSize()} col-md-6 mb-30`}>
                    <div className="services-style-two">
                      <div className="thumb">
                        <img src="../../../assets/theme/img/service_img.jpg" alt="Thumb" />
                        <div className="title">
                          <a type="button" data-bs-toggle="modal" data-bs-target={`#serviceModal${services.id}`} href="#">
                            <i className="flaticon-bar-chart"></i>
                            <h4>{services.service_title}</h4>
                          </a>
                        </div>
                      </div>
                      <div className="info">
                        {/*<p
                          className="text"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                          dangerouslySetInnerHTML={{ __html: services.product_desc }}
                        ></p>*/}
                        <div className="button">
                          <a type="button" data-bs-toggle="modal" data-bs-target={`#serviceModal${services.id}`} href="#">Read More</a>
                          <div className="devider"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        {/* === END SERVICES === */}
        {/* === START CONTACT-US === */}
        <div id="contact" className="contact-style-one-area overflow-hidden half-shape-top customHalf-shape-top-theme7 default-padding-bottom pt-75">

            <div className="contact-shape">
                <img src="../../../assets/theme/img/shape/37.png" alt="Image Not Found" />
            </div>

            <div className="container">
                <div className="row align-center">

                    <div className="contact-stye-one col-lg-5 mb-md-50 mb-xs-20">

                        <div className="contact-style-one-info">
                            <h2>Contact Information</h2>
                            <p>
                              For any questions or concerns, please don't hesitate to contact us.
                            </p>
                            <ul>
                                <li className="wow fadeInUp">
                                    <div className="icon">
                                        <i className="fas fa-phone-alt"></i>
                                    </div>
                                    <div className="content">
                                        <h5 className="title">Hotline</h5>
                                        <a href="" onClick={(e) =>{e.preventDefault();}}>{this.handlePhoneNumber(this.props.theme_data.agent_data[0]["phone"])}</a>
                                    </div>
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="300ms">
                                    <div className="icon">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div className="info">
                                        <h5 className="title">Our Location</h5>
                                        <p>
                                          {this.props.theme_data.agent_data[0]["address"]}{" "}
                                          {this.props.theme_data.agent_data[0]["city"]},{" "}
                                          {this.props.theme_data.agent_data[0]["state"]}{" "}
                                          {this.props.theme_data.agent_data[0]["zip"]}
                                        </p>
                                    </div>
                                </li>
                                <li className="wow fadeInUp" data-wow-delay="500ms">
                                    <div className="icon">
                                        <i className="fas fa-envelope-open-text"></i>
                                    </div>
                                    <div className="info">
                                        <h5 className="title">Official Email</h5>
                                        <a href="" onClick={(e) =>{e.preventDefault();}}>{this.props.theme_data.agent_data[0]["email"]}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="contact-stye-one col-lg-7 pl-60 pl-md-15 pl-xs-15">
                        <div className="contact-form-style-one">
                            <h4 className="sub-heading secondary">Have Questions?</h4>
                            <h2 className="heading">Send us a Massage</h2>
                            <form method="POST" id="contact_form" autoComplete="off" className="contact-form contact-form">
                              <div className="row"><div className="col-lg-12"><div id="aler_error"></div><div id="aler_success"></div></div></div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                          <input
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            defaultValue={this.state.name}
                                            onChange={this.handleChange}
                                            type="text"
                                            placeholder="Name *"
                                            maxLength={100}
                                          />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <input
                                          id="email"
                                          name="email"
                                          className="form-control"
                                          defaultValue={this.state.email}
                                          onChange={this.handleChange}
                                          type="text"
                                          placeholder="Email *"
                                        />
                                      </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                          <input
                                            id="phone"
                                            name="phone"
                                            className="form-control"
                                            defaultValue={this.state.phone}
                                            onChange={this.handleChange}
                                            type="text"
                                            onInput={(e) => {
                                              e.target.value = e.target.value.replace(
                                                /[^0-9]/gi,
                                                ""
                                              );
                                            }}
                                            placeholder="Phone *"
                                            maxLength={12}
                                          />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                          <input
                                            id="subject"
                                            name="subject"
                                            className="form-control"
                                            defaultValue={this.state.subject}
                                            onChange={this.handleChange}
                                            type="text"
                                            placeholder="Subject *"
                                            maxLength={50}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group comments">
                                          <textarea
                                            id="message"
                                            name="message"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            defaultValue={this.state.message}
                                            placeholder="Message *"
                                            maxLength={500}
                                          ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <button id="send_message" className="btn btn-theme btn-md animation">
                                            <i className="fa fa-paper-plane"></i> Get in Touch
                                        </button>
                                    </div>
                                </div>
                                <div className="col-lg-12 alert-notification">
                                    <div id="message" className="alert-msg">
                                    {this.state.errorMessage !== "" ? (
                                      <>
                                        <div style={{ clear: "both" }}></div>
                                        <div
                                          className="alert alert-danger"
                                          role="alert"
                                        >
                                          {this.state.errorMessage}
                                        </div>
                                      </>
                                    ) : null}
                                    {this.state.successMessage !== "" ? (
                                      <>
                                        <div style={{ clear: "both" }}></div>
                                        <div
                                          className="alert alert-success"
                                          role="alert"
                                        >
                                          {this.state.successMessage}
                                        </div>
                                      </>
                                    ) : null}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        {/* === END CONTACT-US === */}
        {/* === START FOOTER === */}
        <footer className="bg-dark text-light">
          <div className="footer-shape">
            <div className="item">
              <img src="../../../assets/theme/img/shape/7.png" alt="Shape" />
            </div>
            <div className="item">
              <img src="../../../assets/theme/img/shape/9.png" alt="Shape" />
            </div>
          </div>
          <div className="container">
            <div className="f-items relative pt-70 pb-120 pt-xs-0 pb-xs-50">
              <div className="row">
                <div className="col-lg-4 col-md-6 footer-item pr-50 pr-xs-15">
                  <div className="f-item about">
                    {this.props.theme_data.logos ? (
                        <img
                          src={`${process.env.REACT_APP_AGENT_PORTAL_URL}files_data/sharelinks/${this.props.theme_data.logos[0]["picture"]}`}
                          alt={this.props.theme_data.logos[0]["image_title"]}
                          className="logo" 
                        />
                      ) : (
                        <img src="../../../assets/img/logo/dark.png" alt="" />
                      )}
                    
                      {this.props.theme_data.agent_data[0].comp_name ? (
                        <p>{this.props.theme_data.agent_data[0].comp_name}</p>
                      ) : (
                        <p>Not Set</p>
                      )
                      
                      }
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-item">
                  <div className="f-item link">
                    <h4 className="widget-title">Our Company</h4>
                    <ul>
                      <li>
                        <a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("home")}}>Home</a>
                      </li>
                      <li>
                        <a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("about")}}>About Us</a>
                      </li>
                      <li>
                        <a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("service")}}>Services</a>
                      </li>
                      <li>
                        <a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("contact")}}>Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-item">
                  <div className="f-item link">
                    <h4 className="widget-title">Products and Services</h4>
                    <ul>
                      {this.props.theme_data.sharelink_services.map(
                        (services) => (
                          <li>
                            <a href="#" onClick={(e) =>{e.preventDefault();this.scrollToSection("service")}}>{services.service_title}</a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 footer-item">
                  <h4 className="widget-title">Our Socials</h4>
                  <p>
                    Stay connected and get the latest updates <br /> by following us on social media!
                  </p>
                  <ul className="footer-social">
                    {this.props.theme_data.sharelink_settings[0]
                      .facebook_link !== "" ? (
                      <li>
                        <a
                          href={
                            this.props.theme_data.sharelink_settings[0]
                              .facebook_link
                          }
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    ) : null}
                    {this.props.theme_data.sharelink_settings[0]
                      .twitter_link !== "" ? (
                      <li>
                        <a
                          href={
                            this.props.theme_data.sharelink_settings[0]
                              .twitter_link
                          }
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                    ) : null}
                    {this.props.theme_data.sharelink_settings[0]
                      .instagram_link !== "" ? (
                      <li>
                        <a
                          href={
                            this.props.theme_data.sharelink_settings[0]
                              .instagram_link
                          }
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    ) : null}
                    {this.props.theme_data.sharelink_settings[0]
                      .linkedin_link !== "" ? (
                      <li>
                        <a
                          href={
                            this.props.theme_data.sharelink_settings[0]
                              .linkedin_link
                          }
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    ) : null}
                    {this.props.theme_data.sharelink_settings[0]
                      .youtube_link !== "" ? (
                      <li>
                        <a
                          href={
                            this.props.theme_data.sharelink_settings[0]
                              .youtube_link
                          }
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Start Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <p>&copy; Copyright {new Date().getFullYear()}. All Rights Reserved by <a href="#">OBS</a></p>
                </div>
                {/*<div className="col-lg-6 text-end">
                  <ul>
                    <li>
                      <a href="about-us.html">Terms</a>
                    </li>
                    <li>
                      <a href="about-us.html">Privacy</a>
                    </li>
                    <li>
                      <a href="contact-us.html">Support</a>
                    </li>
                  </ul>
                </div>*/}
              </div>
            </div>
          </div>
          {/* End Footer Bottom */}
        </footer>
        {/* === END FOOTER === */}
        {/* === START MODALS === */}
        {this.props.theme_data.sharelink_services.map(
          (services) => (
            
            <div key={`serv${services.id}`}>
              <div className="modal fade" id={`serviceModal${services.id}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`serviceModalLabel${services.id}`} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered service_modal">
                  <div className="modal-content">
                  <button type="button" className="btn-close customStyle_modalCloseBtn" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body">
                    <div className="soft-overview customSoft-overview-theme6">
                        <div className="customOverlay-theme3">
                          <div className="services-details-area overflow-hidden pt-50 pb-50">
                              <div className="container">
                                  <div className="services-details-items">
                                      <div className="row">
                                          <div className="order-lg-last px-50 px-md-15 px-xs-15">
                                              <div className="row">
                                                  <div className="col-lg-7">
                                                      <h2 className="d-inline-block">{services.service_title}</h2>
                                                  </div>
                                                  <div className="col-lg-5 order_btn_wrapper_div">
                                                      {services.domain_name && services.domain_name !== "" ? (
                                                        <div className="order_btn_div">
                                                          <a className="btn btn-theme btn-md animation order_btn customStyle-modalBtn mb-10" 
                                                            href={`${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                          >
                                                              Order Now
                                                          </a>
                                                          <h3 className="order_btn_price">${services.rate.toFixed(2)}</h3>
                                                        </div>
                                                      ) : (
                                                        <div className="order_btn_div">
                                                          <a className="btn btn-theme btn-md animation order_btn customStyle-modalBtn mb-10" 
                                                            href={`${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                          >
                                                              Order Now
                                                          </a>
                                                          <h3 className="order_btn_price">${services.rate.toFixed(2)}</h3>
                                                        </div>
                                                      )}
                                                  </div>
                                              </div>
                                              <p className="mb-40 mt-20" dangerouslySetInnerHTML={{ __html: services.product_desc }} />
                                              {services.main_type ===
                                                "ratedeck" ? (
                                                  <div className="services-sidebar">
                                                    <div className="single-widget widget-brochure customWidgetBrochure-theme6">
                                                      <h4 className="widget-title">Files</h4>
                                                      <ul>
                                                        <li><a style={{ maxWidth:"250px" }} href="#"><i style={{ cursor:"pointer", fontSize:"24px", maxWidth:"250px" }} className="fas fa-file"></i>
                                                          <input
                                                            type="hidden"
                                                            name="download_rate_deck"
                                                            className="downloadratedeck"
                                                            value={
                                                              services.rate_deck_file
                                                            }
                                                          />
                                                          Download
                                                          File
                                                          </a></li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                ) : services.main_type === "simple" ? (
                                                  <div className="services-sidebar">
                                                    <div className="single-widget widget-brochure customWidgetBrochure-theme6">
                                                      <h4 className="widget-title">Links</h4>
                                                      <ul>
                                                        <li><a style={{ maxWidth:"250px" }} href={services.marketing_link}><i style={{ cursor:"pointer", fontSize:"20px", maxWidth:"250px" }} className="fa fa-external-link"></i>
                                                          <input
                                                            type="hidden"
                                                            name="download_rate_deck"
                                                            className="downloadratedeck"
                                                            value={
                                                              services.rate_deck_file
                                                            }
                                                          />
                                                          Redirect to link
                                                          </a></li>
                                                      </ul>
                                                    </div>
                                                  </div> ) : null}
                                              <div className="faq-style-one dark mt-40">
                                                  <h3 className="mb-30">Pricing</h3>
                                                  <div className="accordion" id="faqAccordion">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`heading-${services.id}-details`}>
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${services.id}-details`} aria-expanded="false" aria-controls={`collapse-${services.id}-details`}>
                                                               Rates
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse-${services.id}-details`} className="accordion-collapse collapse show" aria-labelledby={`heading-${services.id}-details`} data-bs-parent="#faqAccordion">
                                                            <div className="accordion-body">
                                                            {services.domain_name &&
                                                              services.domain_name !==
                                                                "" ? (
                                                                <input
                                                                  type="hidden"
                                                                  name="click_route"
                                                                  className="click_route"
                                                                  value={`${services.domain_name}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                                />
                                                              ) : (
                                                                <input
                                                                  type="hidden"
                                                                  name="click_route"
                                                                  className="click_route"
                                                                  value={`${process.env.REACT_APP_AGENT_PORTAL_URL}client/register/${this.props.theme_data.agent_data[0]["signup_hash"]}/${services.id}/service`}
                                                                />
                                                              )}
                                                              <div className="table-responsive">
                                                                <table className="table">
                                                                  <thead>
                                                                    <tr>
                                                                      <th>Type</th>
                                                                      <th>Value</th>
                                                                    </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                    {services.service_type ===
                                                                      "did" ||
                                                                    services.service_type ===
                                                                      "tfn" ||
                                                                    services.service_type ===
                                                                      "teams" ? (
                                                                      <>
                                                                        <tr>
                                                                          <th>MRC</th>
                                                                          <td>
                                                                            $
                                                                            {services.mrc.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <th>NRC</th>
                                                                          <td>
                                                                            $
                                                                            {services.nrc.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                        {services.porting_settings &&
                                                                        services
                                                                          .porting_settings
                                                                          .length >
                                                                          0 ? (
                                                                          <>
                                                                            <tr>
                                                                              <th>
                                                                                Port-in
                                                                                Rate
                                                                              </th>
                                                                              <td>
                                                                                $
                                                                                {services.porting_settings[0].portin_sell_rate.toFixed(
                                                                                  2
                                                                                )}
                                                                              </td>
                                                                            </tr>
                                                                            <tr>
                                                                              <th>
                                                                                Port-out
                                                                                Rate
                                                                              </th>
                                                                              <td>
                                                                                $
                                                                                {services.porting_settings[0].portout_sell_rate.toFixed(
                                                                                  2
                                                                                )}
                                                                              </td>
                                                                            </tr>
                                                                          </>
                                                                        ) : null}
                                                                      </>
                                                                    ) : null}
                                                                    {services.main_type !==
                                                                      "ratedeck" &&
                                                                    services.service_type !==
                                                                      "sms" &&
                                                                    services.service_type !==
                                                                      "cnam" ? (
                                                                      <tr>
                                                                        <th>
                                                                        {services.service_type ===
                                                              "generic"
                                                                ? "Price"
                                                                : "Rate Per Minute"}
                                                                        </th>
                                                                        <td>
                                                                          $
                                                                          {services.rate.toFixed(
                                                                            2
                                                                          )}
                                                                        </td>
                                                                      </tr>
                                                                    ) : null}
                                                                    {services.service_type ===
                                                                    "sms" ? (
                                                                      <>
                                                                        <tr>
                                                                          <th>
                                                                            SMS In Rate
                                                                          </th>
                                                                          <td>
                                                                            $
                                                                            {services.sms_in.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <th>
                                                                            SMS Out Rate
                                                                          </th>
                                                                          <td>
                                                                            $
                                                                            {services.sms_out.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                      </>
                                                                    ) : null}
                                                                    {services.service_type ===
                                                                    "cnam" ? (
                                                                      <>
                                                                        <tr>
                                                                          <th>
                                                                            Update CNAM
                                                                            (MRC)
                                                                          </th>
                                                                          <td>
                                                                            $
                                                                            {services.cnam_mrc.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <th>
                                                                            DIP Charges
                                                                          </th>
                                                                          <td>
                                                                            $
                                                                            {services.cnam_dip.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <th>
                                                                            Storage DIP
                                                                            Charges
                                                                          </th>
                                                                          <td>
                                                                            $
                                                                            {services.cnam_dip_local.toFixed(
                                                                              2
                                                                            )}
                                                                          </td>
                                                                        </tr>
                                                                      </>
                                                                    ) : null}
                                                                    <tr>
                                                                      <th>
                                                                        Cancellation Fee
                                                                      </th>
                                                                      <td>
                                                                        $
                                                                        {services.cancellation_fee &&
                                                                        services.cancellation_fee >
                                                                          0
                                                                          ? services.cancellation_fee.toFixed(
                                                                              2
                                                                            )
                                                                          : "0.00"}
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {services.misc_settings &&
                                                      services.misc_settings
                                                        .length > 0 ? (
                                                        <>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id={`heading-${services.id}-misc`}>
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${services.id}-misc`} aria-expanded="false" aria-controls={`collapse-${services.id}-misc`}>
                                                                Miscellaneous Rates
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse-${services.id}-misc`} className="accordion-collapse collapse" aria-labelledby={`heading-${services.id}-misc`} data-bs-parent="#faqAccordion">
                                                                <div className="accordion-body customServiceTableStyles">
                                                                <table className="table">
                                                            <thead>
                                                              <tr>
                                                                <th data-label="Title">Title</th>
                                                                <th data-label="Type">Type</th>
                                                                <th data-label="Rate">Rate</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {services.misc_settings.map(
                                                                (
                                                                  misc,
                                                                  ii
                                                                ) => (
                                                                  <tr
                                                                    key={`mis${ii}`}
                                                                  >
                                                                    <th data-label="Title">
                                                                      {
                                                                        misc.misc_title
                                                                      }
                                                                    </th>
                                                                    <td data-label="Type">
                                                                      {misc.misc_type ===
                                                                      "one_time"
                                                                        ? "One Time"
                                                                        : null}
                                                                      {misc.misc_type ===
                                                                      "per_minute"
                                                                        ? "Per Minute"
                                                                        : null}
                                                                      {misc.misc_type ===
                                                                      "per_hour"
                                                                        ? "Per Hour"
                                                                        : null}
                                                                    </td>
                                                                    <td data-label="Rate">
                                                                      $
                                                                      {Number(misc.misc_rate).toFixed(
                                                                        2
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                      ) : null}
                                                    {services.service_type &&
                                                      services.service_type === "generic" ? (
                                                        <>
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id={`heading-${services.id}-other`}>
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${services.id}-other`} aria-expanded="false" aria-controls={`collapse-${services.id}-other`}>
                                                                Other Services Rates
                                                                </button>
                                                            </h2>
                                                            <div id={`collapse-${services.id}-other`} className="accordion-collapse collapse" aria-labelledby={`heading-${services.id}-other`} data-bs-parent="#faqAccordion">
                                                                <div className="accordion-body customServiceTableStyles">
                                                                <table className="table">
                                                            <thead>
                                                              <tr>
                                                                <th data-label="Title">Title</th>
                                                                <th data-label="Charge Type">Charge Type</th>
                                                                <th data-label="Rate Type">Rate Type</th>
                                                                <th data-label="Rate">Rate</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                            {services.other_service_settings.map(
                                                              (misc, ids) => (
                                                                <tr
                                                                  key={`mis${ids}`}
                                                                >
                                                                  <th data-label="Title">
                                                                    {
                                                                      misc.generic_title
                                                                    }
                                                                  </th>
                                                                  <td data-label="Charge Type">
                                                                    {misc.pay_type ===
                                                                    "recurring"
                                                                      ? "Recurring"
                                                                      : ""}

                                                                    {misc.pay_type ===
                                                                    "one_time"
                                                                      ? "One Time"
                                                                      : ""}
                                                                  </td>
                                                                  <td data-label="Rate Type">
                                                                  {misc.pay_type === "recurring" ? (
                                                                  <>
                                                                  {misc.generic_type ===
                                                                  "per_minute"
                                                                    ? "Per Minute"
                                                                    : ""}
                                                                  {misc.generic_type ===
                                                                  "per_hour"
                                                                    ? "Per Hour"
                                                                    : ""}
                                                                  {misc.generic_type ===
                                                                  "per_second"
                                                                    ? "Per Second"
                                                                    : ""}
                                                                  {misc.generic_type ===
                                                                  "per_day"
                                                                    ? "Per Day"
                                                                    : ""}
                                                                  {misc.generic_type ===
                                                                  "per_month"
                                                                    ? "Per Month"
                                                                    : ""}
                                                                  {misc.generic_type ===
                                                                  "per_year"
                                                                    ? "Per Year"
                                                                    : ""}
                                                                    </>
                                                                ):null}
                                                                  </td>
                                                                  <td data-label="Rate">
                                                                    $
                                                                    {misc.generic_rate &&
                                                                    misc.generic_rate >
                                                                      0
                                                                      ? misc.generic_rate.toFixed(
                                                                          2
                                                                        )
                                                                      : "0.00"}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                          </tbody>
                                                          </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                      ) : null}
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {/*<h3>{services.service_title}</h3>
                          <p
                            className="text"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {services.product_desc}
                          </p>
                          <a data-bs-toggle="modal" data-bs-target={`#serviceModal${services.id}`} href="#"><i className="fas fa-arrow-right"></i></a>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        {/* === END MODALS === */}
        <HelmetProvider>
          <Helmet>
          <script src="../../assets/theme/js/bootstrap.bundle.min.js"></script>
          <script src="../../assets/theme/js/jquery.appear.js"></script>
          <script src="../../assets/theme/js/jquery.easing.min.js"></script>
          <script src="../../assets/theme/js/jquery.magnific-popup.min.js"></script>
          <script src="../../assets/theme/js/modernizr.custom.13711.js"></script>
          <script src="../../assets/theme/js/swiper-bundle.min.js"></script>
          <script src="../../assets/theme/js/wow.min.js"></script>
          <script src="../../assets/theme/js/progress-bar.min.js"></script>
          <script src="../../assets/theme/js/circle-progress.js"></script>
          <script src="../../assets/theme/js/isotope.pkgd.min.js"></script>
          <script src="../../assets/theme/js/imagesloaded.pkgd.min.js"></script>
          <script src="../../assets/theme/js/jquery.nice-select.min.js"></script>
          <script src="../../assets/theme/js/count-to.js"></script>
          <script src="../../assets/theme/js/jquery.scrolla.min.js"></script>
          <script src="../../assets/theme/js/YTPlayer.min.js"></script>
          <script src="../../assets/theme/js/TweenMax.min.js"></script>
          <script src="../../assets/theme/js/rangeSlider.min.js"></script>
          <script src="../../assets/theme/js/jquery-ui.min.js"></script>
          <script src="../../assets/theme/js/validnavs.js"></script>            
          <script src="../../assets/theme/js/main.js"></script>
          </Helmet>
        </HelmetProvider>
      </>
    );
  }
}

export default Theme2;
