import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

class HelperClass {
  constructor() {}
  isLogin() {
    if (
      Cookies.get("acn__") == null ||
      Cookies.get("acn__") === undefined ||
      Cookies.get("acn__") === "" ||
      Cookies.get("tkn__") === undefined ||
      Cookies.get("tkn__") === null ||
      Cookies.get("tkn__") === ""
    ) {
      return false;
    } else {
      var bytes = CryptoJS.AES.decrypt(
        Cookies.get("tkn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (bytes == "") {
        window.location.replace("/login");
        return false;
      }
      var tkn__ = bytes.toString(CryptoJS.enc.Utf8);

      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("acn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        window.location.replace("/login");
        return false;
      }
      var acn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (tkn__ == "" || acn__ == "") {
        window.location.replace("/login");
        return false;
      } else {
        return true;
      }
    }
  }

  isAdminLogin() {
    if (
      Cookies.get("admin_acc__") == null ||
      Cookies.get("admin_acc__") === undefined ||
      Cookies.get("admin_acc__") === "" ||
      Cookies.get("admin_token__") === undefined ||
      Cookies.get("admin_token__") === null ||
      Cookies.get("admin_token__") === ""
    ) {
      return false;
    } else {
      var bytes = CryptoJS.AES.decrypt(
        Cookies.get("admin_token__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (bytes == "") {
        window.location.replace("/nsaccess");
        return false;
      }
      var admin_token__ = bytes.toString(CryptoJS.enc.Utf8);

      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_acc__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        window.location.replace("/nsaccess");
        return false;
      }
      var admin_acc__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_token__ == "" || admin_acc__ == "") {
        window.location.replace("/nsaccess");
        return false;
      } else {
        return true;
      }
    }
  }

  getCompName() {
    if (
      Cookies.get("comp_nme__") == null ||
      Cookies.get("comp_nme__") === undefined ||
      Cookies.get("comp_nme__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("comp_nme__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var comp_nme__ = secByte.toString(CryptoJS.enc.Utf8);

      if (comp_nme__ == "") {
        return false;
      } else {
        return comp_nme__;
      }
    }
  }

  getName() {
    if (
      Cookies.get("name__") == null ||
      Cookies.get("name__") === undefined ||
      Cookies.get("name__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("name__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var name__ = secByte.toString(CryptoJS.enc.Utf8);

      if (name__ == "") {
        return false;
      } else {
        return name__;
      }
    }
  }

  getEmail() {
    if (
      Cookies.get("eml__") == null ||
      Cookies.get("eml__") === undefined ||
      Cookies.get("eml__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("eml__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var eml__ = secByte.toString(CryptoJS.enc.Utf8);

      if (eml__ == "") {
        return false;
      } else {
        return eml__;
      }
    }
  }

  getTitle() {
    if (
      Cookies.get("title__") == null ||
      Cookies.get("title__") === undefined ||
      Cookies.get("title__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("title__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var title__ = secByte.toString(CryptoJS.enc.Utf8);

      if (title__ == "") {
        return false;
      } else {
        return title__;
      }
    }
  }

  getAddress() {
    if (
      Cookies.get("adress__") == null ||
      Cookies.get("adress__") === undefined ||
      Cookies.get("adress__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("adress__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var adress__ = secByte.toString(CryptoJS.enc.Utf8);

      if (adress__ == "") {
        return false;
      } else {
        return adress__;
      }
    }
  }

  getPhone() {
    if (
      Cookies.get("phone__") == null ||
      Cookies.get("phone__") === undefined ||
      Cookies.get("phone__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("phone__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var phone__ = secByte.toString(CryptoJS.enc.Utf8);

      if (phone__ == "") {
        return false;
      } else {
        return phone__;
      }
    }
  }

  getAccount() {
    if (
      Cookies.get("acn__") == null ||
      Cookies.get("acn__") === undefined ||
      Cookies.get("acn__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("acn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var acn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (acn__ == "") {
        return false;
      } else {
        return acn__;
      }
    }
  }

  getToken() {
    if (
      Cookies.get("tkn__") == null ||
      Cookies.get("tkn__") === undefined ||
      Cookies.get("tkn__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("tkn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var tkn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (tkn__ == "") {
        return false;
      } else {
        return tkn__;
      }
    }
  }

  getAdminType() {
    if (
      Cookies.get("admin_type__") == null ||
      Cookies.get("admin_type__") === undefined ||
      Cookies.get("admin_type__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_type__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_type__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_type__ == "") {
        return false;
      } else {
        return admin_type__;
      }
    }
  }

  getAdminName() {
    if (
      Cookies.get("admin_name__") == null ||
      Cookies.get("admin_name__") === undefined ||
      Cookies.get("admin_name__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_name__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_name__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_name__ == "") {
        return false;
      } else {
        return admin_name__;
      }
    }
  }

  getAdminFullName() {
    if (
      Cookies.get("full_name__") == null ||
      Cookies.get("full_name__") === undefined ||
      Cookies.get("full_name__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("full_name__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var full_name__ = secByte.toString(CryptoJS.enc.Utf8);

      if (full_name__ == "") {
        return false;
      } else {
        return full_name__;
      }
    }
  }

  getAdminEmail() {
    if (
      Cookies.get("admin_eml__") == null ||
      Cookies.get("admin_eml__") === undefined ||
      Cookies.get("admin_eml__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_eml__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_eml__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_eml__ == "") {
        return false;
      } else {
        return admin_eml__;
      }
    }
  }

  getAdminAccount() {
    if (
      Cookies.get("admin_acc__") == null ||
      Cookies.get("admin_acc__") === undefined ||
      Cookies.get("admin_acc__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_acc__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_acc__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_acc__ == "") {
        return false;
      } else {
        return admin_acc__;
      }
    }
  }

  getAdminToken() {
    if (
      Cookies.get("admin_token__") == null ||
      Cookies.get("admin_token__") === undefined ||
      Cookies.get("admin_token__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("admin_token__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var admin_token__ = secByte.toString(CryptoJS.enc.Utf8);

      if (admin_token__ == "") {
        return false;
      } else {
        return admin_token__;
      }
    }
  }

  getResellerIdentifier() {
    if (
      Cookies.get("residentifier__") == null ||
      Cookies.get("residentifier__") === undefined ||
      Cookies.get("residentifier__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("residentifier__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var residentifier__ = secByte.toString(CryptoJS.enc.Utf8);

      if (residentifier__ == "") {
        return false;
      } else {
        return residentifier__;
      }
    }
  }

  getResellerRegToken() {
    if (
      Cookies.get("restkn__") == null ||
      Cookies.get("restkn__") === undefined ||
      Cookies.get("restkn__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("restkn__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var restkn__ = secByte.toString(CryptoJS.enc.Utf8);

      if (restkn__ == "") {
        return false;
      } else {
        return restkn__;
      }
    }
  }

  getResellerRegAcc() {
    if (
      Cookies.get("resacct__") == null ||
      Cookies.get("resacct__") === undefined ||
      Cookies.get("resacct__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("resacct__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var resacct__ = secByte.toString(CryptoJS.enc.Utf8);

      if (resacct__ == "") {
        return false;
      } else {
        return resacct__;
      }
    }
  }
  getResellerRegType() {
    //Agent or Sub Agent
    if (
      Cookies.get("resregtype__") == null ||
      Cookies.get("resregtype__") === undefined ||
      Cookies.get("resregtype__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("resregtype__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var resregtype__ = secByte.toString(CryptoJS.enc.Utf8);

      if (resregtype__ == "") {
        return false;
      } else {
        return resregtype__;
      }
    }
  }

  getResellerRegTitle() {
    //Agent or Sub Agent
    if (
      Cookies.get("restitle__") == null ||
      Cookies.get("restitle__") === undefined ||
      Cookies.get("restitle__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("restitle__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var restitle__ = secByte.toString(CryptoJS.enc.Utf8);

      if (restitle__ == "") {
        return false;
      } else {
        return restitle__;
      }
    }
  }

  getResellerLogo() {
    //Agent or Sub Agent
    if (
      Cookies.get("reslogo__") == null ||
      Cookies.get("reslogo__") === undefined ||
      Cookies.get("reslogo__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("reslogo__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var reslogo__ = secByte.toString(CryptoJS.enc.Utf8);

      if (reslogo__ == "") {
        return false;
      } else {
        return reslogo__;
      }
    }
  }

  getResellerSignature() {
    //Agent or Sub Agent
    if (
      Cookies.get("ressig__") == null ||
      Cookies.get("ressig__") === undefined ||
      Cookies.get("ressig__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("ressig__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var ressig__ = secByte.toString(CryptoJS.enc.Utf8);

      if (ressig__ == "") {
        return false;
      } else {
        return ressig__;
      }
    }
  }

  getSuperAgentTitle() {
    //Agent or Sub Agent
    if (
      Cookies.get("agttitle__") == null ||
      Cookies.get("agttitle__") === undefined ||
      Cookies.get("agttitle__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("agttitle__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var agttitle__ = secByte.toString(CryptoJS.enc.Utf8);

      if (agttitle__ == "") {
        return false;
      } else {
        return agttitle__;
      }
    }
  }

  getSuperAgentAcct() {
    //Agent or Sub Agent
    if (
      Cookies.get("agtacctno__") == null ||
      Cookies.get("agtacctno__") === undefined ||
      Cookies.get("agtacctno__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("agtacctno__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var agtacctno__ = secByte.toString(CryptoJS.enc.Utf8);

      if (agtacctno__ == "") {
        return false;
      } else {
        return agtacctno__;
      }
    }
  }

  logout() {
    Cookies.remove("comp_nme__");
    Cookies.remove("eml__");
    Cookies.remove("acn__");
    Cookies.remove("tkn__");
    //Get Reseller Url
    var resByte = CryptoJS.AES.decrypt(
      Cookies.get("residentifier__"),
      process.env.REACT_APP_SIGNATURE
    );

    var residentifier = resByte.toString(CryptoJS.enc.Utf8);
    return window.location.replace("/login/" + residentifier);
  }

  adminlogout() {
    Cookies.remove("admin_name__");
    Cookies.remove("admin_type__");
    Cookies.remove("admin_eml__");
    Cookies.remove("admin_acc__");
    Cookies.remove("admin_token__");
    return window.location.replace("/nsaccess");
  }
}

export default HelperClass;
