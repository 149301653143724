import ImgError from "../../assets/images/error-404.svg";
import { THEME_COLOR } from "./../../config/config";

export default function error() {
  return (
    <div
      class="iknow_tm_all_wrap"
      data-magic-cursor="show"
      data-animation-in="zoomIn"
      data-animation-out="zoomOut"
    >
      {/* If you want disable magic cursor change value to "hide" */}

      {/* MODALBOX */}
      <div class="iknow_tm_modalbox">
        <div class="box_inner">
          <div class="close">
            <a href="#">
              <img
                class="svg"
                src="../../../assets/img/svg/cancel.svg"
                alt=""
              />
            </a>
          </div>
          <div class="description_wrap"></div>
        </div>
      </div>
      {/* /MODALBOX */}

      <div class="iknow_tm_extra_demo_2">
        <span class="top_shape"></span>
        <span class="bottom_shape"></span>

        {/* TOPBAR */}
        <div class="iknow_tm_topbar smaller onepage"></div>
        {/* /TOPBAR */}

        {/* MOBILE MENU */}
        <div class="iknow_tm_mobile_menu">
          <div class="mobile_menu_inner">
            <div class="mobile_in">
              <div class="logo">
                <a href="#">
                  <img src="../../../assets/img/logo/dark.png" alt="" />
                </a>
              </div>
              <div class="trigger">
                <div class="hamburger hamburger--slider">
                  <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dropdown">
            <div class="dropdown_inner"></div>
          </div>
        </div>
        {/* /MOBILE MENU */}

        {/* MAINPART */}
        <div class="iknow_tm_mainpart_new_2">
          {/* SOCIAL */}
          <div class="social_list"></div>
          {/* /SOCIAL */}

          {/* MENU */}
          <div class="main_menu"></div>
          {/* /MENU */}

          {/* HERO */}
          <div class="iknow_tm_hero_fifth" id="home">
            <div class="background">
              <div class="image"></div>
              <div class="overlay"></div>
            </div>
            <div class="content">
              <div class="details">
                <img class="sign" src={ImgError} alt="" />
                <br />
                <span class="hello">Oops! Why you’re here?</span>
                <h3 class="name" style={{ color: "#364a63" }}>
                  Page Not Found
                </h3>
                <p class="text" style={{ color: "#364a63" }}>
                  We are very sorry for inconvenience. It looks like you’re try
                  to access a page that either has been deleted or never
                  existed.
                </p>
              </div>
            </div>
          </div>
          {/* /HERO */}

          {/* COPYRIGHT */}
          <div class="iknow_tm_copyright smaller onepage empty"></div>
          {/* /COPYRIGHT */}
        </div>
        {/* /MAINPART */}
      </div>

      {/* MAGIC CURSOR */}
      <div class="mouse-cursor cursor-outer"></div>
      <div class="mouse-cursor cursor-inner"></div>
      {/* /MAGIC CURSOR */}
    </div>
  );
}
